<template>
  <div class="globalGoods">
    <div class="cell">
      <div style="display: flex; justify-content: space-between">
        <div style="display: flex; align-items: center; width: 49%">
          <div style="width: 100px; padding-right: 20px">商品名称</div>
          <el-input placeholder="输入" v-model="formData.title" size="medium">
          </el-input>
        </div>
        <div style="display: flex; align-items: center; width: 49%">
          <div style="width: 100px; text-align: right; padding-right: 20px">
            类别
          </div>
          <el-input placeholder="选择分类" suffix-icon="el-icon-edit" size="medium" @focus="openPop" v-model="classData"
            readonly>
          </el-input>
        </div>
      </div>

      <div style="display: flex; margin-top: 20px; justify-content: space-between">
        <div style="display: flex; align-items: center; width: 49%">
          <div style="width: 100px">店铺</div>
          <el-select style="width: 100%" v-model="formData.shop_id" placeholder="请选择">
            <el-option v-for="item in shopList" :key="item.id" :label="item.shop_name" :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div style="display: flex; align-items: center; width: 48.1%">
          <div style="width: 160px; padding-right: 20px; text-align: right">
            商品数量
          </div>
          <el-input placeholder="输入" v-model="goodsNum1" size="medium">
          </el-input>
          <div style="padding: 0 10px">-</div>
          <el-input placeholder="输入" v-model="goodsNum2" size="medium">
          </el-input>
        </div>
        <!-- <div style="display: flex; align-items: center; width: 49%">
          <div style="width: 188px; padding-right: 20px; text-align: right">
            销售
          </div>
          <el-input placeholder="输入" v-model="salesNum1" size="medium">
          </el-input>
          <div style="padding: 0 10px">-</div>
          <el-input placeholder="输入" v-model="salesNum2" size="medium">
          </el-input>
        </div> -->
      </div>
      <div style="margin-top: 20px">
        <el-button type="primary" size="medium" @click="handleSearech">搜索</el-button>
        <el-button size="medium" plain @click="handleInit">重设</el-button>
      </div>
    </div>
    <div class="cell2">
      <div style="
          margin-top: 20px;
          display: flex;
          justify-content: space-between;
          align-items: end;
        ">
        <div>
          <div style="font-size: 18px">{{ listData.total }}全球商品</div>
        </div>
        <div>
          <el-button icon="el-icon-plus" type="primary" @click="toAdd">新增全球商品</el-button>
          <!-- <el-button @click="toSend">发布店铺商品</el-button> -->
        </div>
      </div>
      <div class="list">
        <div class="head">
          <!-- <div style="width: 5%">
            <el-checkbox
              :indeterminate="isIndeterminate"
              v-model="alls"
              @change="all"
              :disabled="listData.list.length == 0"
            ></el-checkbox>
          </div> -->
          <div style="width: 30%">商品名称</div>
          <div style="display: flex; width: 100%">
            <div style="width: 20%">规格货号</div>
            <div style="width: 20%">规格</div>
            <div style="
                width: 20%;
                display: flex;
                align-items: center;
                cursor: pointer;
              ">
              全球商品价格
              <span style="
                  display: flex;
                  flex-direction: column;
                  font-size: 12px;
                  margin-left: 4px;
                ">
                <i class="el-icon-caret-top" @click="sorting(1, 1)" ref="jiao1" style="margin-bottom: -3px"></i><i
                  @click="sorting(1, 2)" class="el-icon-caret-bottom" ref="jiao2" style="margin-top: -3px"></i>
              </span>
            </div>
            <div style="
                width: 20%;
                display: flex;
                align-items: center;
                cursor: pointer;
              ">
              商品数量
              <span style="
                  display: flex;
                  flex-direction: column;
                  font-size: 12px;
                  margin-left: 4px;
                ">
                <i class="el-icon-caret-top" @click="sorting(2, 1)" ref="jiao3" style="margin-bottom: -3px"></i><i
                  class="el-icon-caret-bottom" ref="jiao4" @click="sorting(2, 2)" style="margin-top: -3px"></i>
              </span>
            </div>
            <div style="
                width: 20%;
                display: flex;
                align-items: center;
                cursor: pointer;
              ">
              销售
              <span style="
                  display: flex;
                  flex-direction: column;
                  font-size: 12px;
                  margin-left: 4px;
                ">
                <i class="el-icon-caret-top" @click="sorting(3, 1)" ref="jiao5" style="margin-bottom: -3px"></i><i
                  class="el-icon-caret-bottom" @click="sorting(3, 2)" ref="jiao6" style="margin-top: -3px"></i>
              </span>
            </div>
          </div>
          <div style="width: 15%; max-width: 100px">已发布的商品</div>
          <div style="width: 10%; padding-left: 40px">操作</div>
        </div>
      </div>

      <div v-if="httpShow" class="conter">
        <div v-if="listData.list.length != 0">
          <div class="item" style="display: flex" v-for="val in listData.list" :key="val.id">
            <!-- <div style="width: 5%; padding-top: 20px">
              <el-checkbox-group
                text-color="#fff"
                v-model="checkedCities"
                @change="single"
              >
                <el-checkbox :label="val.id">&ensp;</el-checkbox>
              </el-checkbox-group>
            </div> -->
            <div style="width: 30%; max-width: 200px; display: flex">
              <el-image style="width: 60px; height: 60px; flex-shrink: 0" :src="val.image" fit="fit">
                <svg slot="error" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 61" fill="#e5e4e4"
                  style="width: 50px; height: 50px">
                  <path
                    d="M51.2 16.9H38.7C38.7 11.6 36 .6 27 .5 17.4.4 15.2 12.4 15.2 16.9H2.8c-3.4 0-2.7 3.4-2.7 3.4l2.4 33s-.1 7.3 6.3 7.5h36.5c6.2-.4 6.3-7.5 6.3-7.5l2.4-33c0-.1.5-3.5-2.8-3.4zM27.1 4.2c7.1.2 7.9 11.7 7.7 12.6H19.1c-.1-.9.4-12.4 8-12.6zm9.1 44.6c-1 1.7-2.7 3-5 3.7-1.2.4-2.4.5-3.6.5-3.2 0-6.5-1.1-9.3-3.3-.8-.6-1-1.5-.5-2.3.2-.4.7-.7 1.2-.8.4-.1.9 0 1.2.3 3.2 2.4 8.3 4 11.9 1.6 1.4-.9 2.1-2.7 1.6-4.3-.5-1.6-2.2-2.7-3.5-3.4-1-.6-2.1-1-3.3-1.4-.9-.3-1.9-.7-2.9-1.2-2.4-1.2-4-2.6-4.8-4.2-1.2-2.3-.6-5.4 1.4-7.5 3.6-3.8 10-3.2 14-.4.9.6.9 1.7.4 2.5s-1.4.9-2.2.4c-2-1.4-4.4-2-6.4-1.7-2 .3-4.7 2-4.4 4.6.2 1.5 2 2.6 3.3 3.3.8.4 1.5.7 2.3.9 4.3 1.3 7.2 3.3 8.6 5.7 1.2 2.1 1.2 4.9 0 7z" />
                </svg></el-image>
              <div style="width: 70%; padding-right: 20px; margin-left: 10px">
                <div>
                  <el-popover placement="top" trigger="hover" :content="val.title">
                    <div class="hang2" slot="reference" style="font-size: 14px; max-width: 140px">
                      {{ val.title }}
                    </div>
                  </el-popover>

                  <div style="font-size: 12px; margin-top: 6px">
                    全球商品货号：{{ val.goods_sn }}
                  </div>
                  <div style="color: #a1a1a1; font-size: 12px; margin-top: 6px">
                    <i class="el-icon-view"></i>
                    <span style="margin: 0 4px">0</span>
                    <i class="el-icon-star-off"></i>
                    <span style="margin: 0 4px">{{ val.like }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div style="width: 100%; padding-bottom: 20px; position: relative" :style="{
              'border-bottom':
                val.specification.length > 3 ? '1px dashed #e5e5e5' : 'none',
            }">
              <div style="display: flex; padding-bottom: 20px" v-for="(item, i) in val.data" :key="i">
                <div style="width: 20%">{{ item.sn ? item.sn : "-" }}</div>
                <div style="width: 20%">
                  {{ item.difference ? item.difference : "-" }}
                </div>
                <div style="width: 20%">
                  ￥{{ item.price ? item.price : val.price }}
                </div>
                <div style="width: 20%">
                  {{ item.stock ? item.stock : val.stock }}
                </div>
                <div style="width: 20%">
                  {{ val.specification.length != 0 ? item.sales : val.sales }}
                </div>
                <div class="line" v-if="val.specification.length > 3 && !val.openShow">
                  <span @click="openHidden(val)">
                    更多({{ val.specification.length - 3 }}个商品规格货号)
                    <i style="font-size: 14px" class="el-icon-arrow-down"></i></span>
                </div>
                <div class="line" v-if="val.openShow">
                  <span @click="closeHidden(val)">
                    收起
                    <i style="font-size: 14px" class="el-icon-arrow-up"></i></span>
                </div>
              </div>
            </div>
            <div style="width: 15%; max-width: 100px">
              <div style="margin-bottom: 4px" class="single-hidden" v-for="e in val.goodsCommonShop" :key="e.id">
                {{ e.shop_name }}
              </div>
            </div>
            <div style="width: 10%; padding-left: 40px" v-if="activeName != 3">
              <div class="btn" @click="editGoods(val)">编辑</div>
              <div class="btn" @click="delGoods(0, val)">删除</div>
              <el-tooltip v-if="!val.fb" effect="dark" content="全球商品已发布到所有市场" placement="top">
                <span class="btns">发布</span>
              </el-tooltip>

              <div class="btn" v-else @click="toSend(val)">发布</div>

              <!-- <div
                class="btn"
                v-if="val.status == 1"
                @click="handlePromote(val)"
              >
                推广
              </div>
              <div class="btn" @click="handelLook(val)" v-if="val.status">
                预览
              </div>
              <div class="btn" @click="handleShelves(val)">
                {{ val.status == 0 ? "上架" : val.status == 1 ? "下架" : "" }}
              </div> -->
            </div>
            <div v-else style="width: 10%; padding-left: 40px"></div>
          </div>
        </div>
        <div v-else>
          <el-empty description="找不到商品"></el-empty>
        </div>
      </div>
      <div v-else style="text-align: center; padding-top: 20px">
        <img :src="lodingImg" alt="" />
      </div>

      <div style="padding-top: 20px; display: flex; justify-content: right">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="paging.page"
          :page-sizes="[10, 20, 30, 40]" :page-size="paging.pageSize" layout="prev,  pager, next,sizes"
          :total="paging.total">
        </el-pagination>
      </div>

      <div class="bottom-float-btn" v-if="false">
        <div>
          <el-checkbox :indeterminate="isIndeterminate" v-model="alls" @change="all"
            :disabled="listData.list.length == 0"><span style="font-size: 16px; color: #000">选择全部</span></el-checkbox>
        </div>
        <div>
          已选择{{ checkedCities.length }}个商品
          <el-button size="medium" plain style="margin-left: 20px" @click="delGoods(1)">删除</el-button>
          <el-button size="medium" v-if="activeName == 1 || activeName == 4" plain @click="handleShelvesAll()">{{
            activeName == 1 ? "下架" : "上架" }}</el-button>
        </div>
      </div>
    </div>
    <el-dialog title="选择分类" :visible.sync="dialogVisible" width="1200px" :before-close="handleClose">
      <div class="add-pops">
        <el-input style="width: 400px" v-model="categoryName" size="medium" placeholder="类目名称"
          prefix-icon="el-icon-search" clearable @clear="getCategory" @keydown.native="searchCategory()"></el-input>
        <div>
          <el-cascader-panel style="
              background: #fff;
              margin-top: 20px;
              min-height: 300px;
              max-height: 400px;
            " v-model="categoryData" @change="change" :options="options" :props="configuration"></el-cascader-panel>
        </div>
        <div style="font-size: 14px; margin-top: 20px">
          正在选择的：<span style="color: #999999">{{
            areChoosing ? areChoosing : "没有选择任何类别"
          }}</span>
        </div>
        <div style="display: flex; justify-content: right">
          <el-button plain @click="handleClose">取消</el-button>
          <el-button type="primary" @click="handleCategory">确认</el-button>
        </div>
      </div>
    </el-dialog>

    <!-- 删除操作 -->
    <el-dialog title="删除商品" :visible.sync="dialogVisible1" width="30%" :before-close="handleClose1">
      <div>
        <div>
          您确定要删除以下{{
            delData.length
          }}个全球商品吗？所有关联的店铺商品均会被删除。
        </div>
        <div style="margin-top: 10px">
          <div style="display: flex; margin-top: 4px" v-for="(item, i) in delData" :key="i">
            <div>
              <el-image style="width: 60px; height: 60px" :src="item.image" fit="fit">
                <svg slot="error" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 61" fill="#e5e4e4"
                  style="width: 50px; height: 50px">
                  <path
                    d="M51.2 16.9H38.7C38.7 11.6 36 .6 27 .5 17.4.4 15.2 12.4 15.2 16.9H2.8c-3.4 0-2.7 3.4-2.7 3.4l2.4 33s-.1 7.3 6.3 7.5h36.5c6.2-.4 6.3-7.5 6.3-7.5l2.4-33c0-.1.5-3.5-2.8-3.4zM27.1 4.2c7.1.2 7.9 11.7 7.7 12.6H19.1c-.1-.9.4-12.4 8-12.6zm9.1 44.6c-1 1.7-2.7 3-5 3.7-1.2.4-2.4.5-3.6.5-3.2 0-6.5-1.1-9.3-3.3-.8-.6-1-1.5-.5-2.3.2-.4.7-.7 1.2-.8.4-.1.9 0 1.2.3 3.2 2.4 8.3 4 11.9 1.6 1.4-.9 2.1-2.7 1.6-4.3-.5-1.6-2.2-2.7-3.5-3.4-1-.6-2.1-1-3.3-1.4-.9-.3-1.9-.7-2.9-1.2-2.4-1.2-4-2.6-4.8-4.2-1.2-2.3-.6-5.4 1.4-7.5 3.6-3.8 10-3.2 14-.4.9.6.9 1.7.4 2.5s-1.4.9-2.2.4c-2-1.4-4.4-2-6.4-1.7-2 .3-4.7 2-4.4 4.6.2 1.5 2 2.6 3.3 3.3.8.4 1.5.7 2.3.9 4.3 1.3 7.2 3.3 8.6 5.7 1.2 2.1 1.2 4.9 0 7z" />
                </svg></el-image>
            </div>
            <div style="
                font-size: 12px;
                margin: 0 20px;
                border-bottom: 1px dashed #e8e8e8;
                width: 100%;
              ">
              {{ item.title }}
              <br />
              <span style="color: #969696">[{{ item.goods_sn }}]</span>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false" plain>取消</el-button>
        <el-button type="primary" @click="delGoods(3)">删除</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { categoryInfo } from "@/api/common.js";
import { getGoodsCommonList, delGoods, shopSearch } from "@/api/shopGoods.js";
import env from "@/settings/env.js";
export default {
  data() {
    return {
      allShopId: [],
      baseURL: env.fileUrlPrefix,
      activeName: "0",
      listData: {
        list: [],
      },
      classData: "",
      goodsNum1: "",
      goodsNum2: "",
      salesNum1: "",
      salesNum2: "",
      formData: {
        shop_id: "",
        title: "",
      },
      isIndeterminate: false,
      alls: false,
      dialogVisible: false,
      dialogVisible1: false,
      categoryName: "",
      areChoosing: false,
      options: [],
      configuration: {
        disabled: false,
        value: "category_id",
        label: "name",
        children: "list",
      },
      categoryData: [],
      checkedCities: [],
      url: "",
      online_number: 0,
      percentage: 0,
      paging: {
        total: 0,
        pageSize: 10,
        page: 1,
      },
      lodingImg: require("@/assets/img/app/http.gif"),
      httpShow: false,
      delData: [],
      sortData: {
        field: "",
        order: 0,
      },
      shopList: [],
      shop_id: "",
    };
  },
  created() {
    this.getCategory();
    this.getShop();
    this.getList();
  },
  methods: {
    getShop() {
      shopSearch().then((res) => {
        if (res.code == 1) {
          this.shopList = res.data;
          let arr = [];
          this.shopList.forEach((item) => {
            arr.push(item.id);
          });
          this.allShopId = arr;
        }
      });
    },
    // 获取商品列表
    getList() {
      this.httpShow = false;
      getGoodsCommonList({
        state: this.activeName,
        page: this.paging.page,
        page_size: this.paging.pageSize,
        ...this.formData,
        ...this.sortData,
        category_path_id: this.categoryData[this.categoryData.length - 1],
      }).then((res) => {
        if (res.code == 1) {
          this.httpShow = true;
          this.paging.total = res.data.total;
          this.listData = res.data;
          this.listData.list.forEach((item) => {
            item.fb = false;
            item.openShow = false;
            if (item.specification.length != 0) {
              if (item.specification.length > 3) {
                item.data = [
                  item.specification[0],
                  item.specification[1],
                  item.specification[2],
                ];
              } else {
                item.data = item.specification;
              }
            } else {
              item.data = [1];
            }

            if (
              item.goodsCommonShop.length != 0 &&
              this.allShopId.length != 0
            ) {
              let n = [];
              item.goodsCommonShop.forEach((val) => {
                n.push(val.shop_id);
              });

              this.shopList.forEach((val) => {
                if (n.indexOf(val.id) == -1) {
                  item.fb = true;
                }
              });
            } else {
              item.fb = true;
            }
          });
        }
      });
    },

    // 搜索
    handleSearech() {
      this.formData["goods_num"] =
        this.goodsNum1 && this.goodsNum2
          ? this.goodsNum1 + "-" + this.goodsNum2
          : "";
      this.formData["sales"] =
        this.salesNum1 && this.salesNum2
          ? this.salesNum1 + "-" + this.salesNum2
          : "";
      this.paging.page = 1;
      this.getList();
    },
    // 全选
    all(val) {
      let arr = [];
      if (val) {
        this.listData.list.forEach((item) => {
          arr.push(item.id);
        });
      }
      this.checkedCities = arr;
      this.isIndeterminate = false;
    },
    // 添加商品
    toAdd() {
      sessionStorage.ADD_GOODS_TYPE = 1;
      this.$router.options.routes.forEach((item) => {
        if (item.name == "myGoodsz") {
          item.children.forEach((val) => {
            if (val.name == "addGoods") {
              val.meta.bread[1].name = "全球商品";
              val.meta.bread[1].path = "globalGoods";
            }
            if (val.name == "addGoodsInfo") {
              val.meta.bread[1].name = "全球商品";
              val.meta.bread[1].path = "globalGoods";
            }
          });
        }
      });


      // this.$router.push("addGoods");
      this.$router.push("addGoodsInfo");
    },
    // 获取类目列表
    getCategory() {
      categoryInfo({
        keyword: this.categoryName,
      }).then((res) => {
        this.options = res.data;
      });
    },
    // 打开类目弹窗
    openPop() {
      this.dialogVisible = true;
    },
    // 关闭类目弹窗
    handleClose() {
      this.areChoosing = "";
      this.classData = "";
      this.categoryData = [];
      this.categoryName = "";
      this.dialogVisible = false;
    },
    // 关闭删除操作弹窗
    handleClose1() {
      this.dialogVisible1 = false;
    },
    // 全选数据变化
    change(value) {
      let data = "";
      if (value[0]) {
        this.options.forEach((item) => {
          if (item.category_id == value[0]) {
            this.areChoosing = item.name;
            data = item;
          }
        });
      }
      if (value[1]) {
        data.list.forEach((item) => {
          if (item.category_id == value[1]) {
            this.areChoosing = this.areChoosing + "-" + item.name;
            data = item;
          }
        });
      }
      if (value[2]) {
        data.list.forEach((item) => {
          if (item.category_id == value[2]) {
            this.areChoosing = this.areChoosing + "-" + item.name;
          }
        });
      }
    },
    // 类目选择确认
    handleCategory() {
      let data = "";
      if (this.categoryData.length == 0) {
        this.$message.error("您未选择任何分类");
        return false;
      }
      if (this.categoryData[0]) {
        this.options.forEach((item) => {
          if (item.category_id == this.categoryData[0]) {
            this.classData = item.name;
            data = item;
          }
        });
      }
      if (this.categoryData[1]) {
        data.list.forEach((item) => {
          if (item.category_id == this.categoryData[1]) {
            this.classData = this.classData + "-" + item.name;
            data = item;
          }
        });
      }
      if (this.categoryData[2]) {
        data.list.forEach((item) => {
          if (item.category_id == this.categoryData[2]) {
            this.classData = this.classData + "-" + item.name;
          }
        });
      }
      this.dialogVisible = false;
    },
    // 搜索类目
    searchCategory(e) {
      let evt = window.event || e;
      if (evt.keyCode == 13) {
        this.categoryData = "";
        this.areChoosing = false;
        this.getCategory();
      }
    },
    // 全选状态
    single(value) {
      let checkedCount = value.length;
      this.alls = checkedCount == this.listData.list.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.listData.list.length;
    },

    // 删除商品
    delGoods(type, val) {
      let arr = [];
      this.dialogVisible1 = true;
      if (type == 0) {
        arr.push(val);
        this.delData = arr;
      }
      if (type == 1) {
        this.checkedCities.forEach((item) => {
          this.listData.lsit.forEach((val) => {
            if (val.id == item) {
              arr.push(val);
            }
          });
        });
        this.delData = arr;
      }
      if (type == 3) {
        let data = this.delData.map((item) => {
          return item.id;
        });
        delGoods({
          goods_id: data.toString(),
        }).then((res) => {
          if (res.code == 1) {
            this.dialogVisible1 = false;
            this.getList();
            this.checkedCities = [];
            this.isIndeterminate = false;
            this.$message.success(res.message);
          }
        });
      }
    },
    openHidden(val) {
      val.datas = val.data;
      val.data = val.specification;
      val.openShow = true;
      this.$forceUpdate();
    },
    closeHidden(val) {
      val.data = val.datas;
      val.openShow = false;
      this.$forceUpdate();
    },
    handleSizeChange(pageSize) {
      this.paging.pageSize = pageSize;
      this.getList();
    },
    handleCurrentChange(page) {
      this.paging.page = page;
      this.getList();
    },
    handleInit() {
      this.formData = {
        shop_id: "",
        title: "",
      };
      this.classData = "";
      this.categoryData = [];
      this.goodsNum1 = "";
      this.goodsNum2 = "";
      this.salesNum1 = "";
      this.salesNum2 = "";
      this.formData.goods_num = "";
      this.formData.sales = "";
      this.paging.page = 1;
      this.getList();
    },
    // 排序
    sorting(type, order) {
      this.gsCL();
      if (type == 1) {
        this.sortData.field = "global_price";
        if (order == 1) {
          this.$refs.jiao1.style.color = "#f17157";
        } else {
          this.$refs.jiao2.style.color = "#f17157";
        }
      }
      if (type == 2) {
        this.sortData.field = "stock";
        if (order == 1) {
          this.$refs.jiao3.style.color = "#f17157";
        } else {
          this.$refs.jiao4.style.color = "#f17157";
        }
      }
      if (type == 3) {
        this.sortData.field = "sales";
        if (order == 1) {
          this.$refs.jiao5.style.color = "#f17157";
        } else {
          this.$refs.jiao6.style.color = "#f17157";
        }
      }
      this.sortData.order = order;
      this.getList();
    },
    gsCL() {
      this.$refs.jiao1.style.color = "#717373";
      this.$refs.jiao2.style.color = "#717373";
      this.$refs.jiao3.style.color = "#717373";
      this.$refs.jiao4.style.color = "#717373";
      this.$refs.jiao5.style.color = "#717373";
      this.$refs.jiao6.style.color = "#717373";
    },
    editGoods(val) {
      sessionStorage.ADD_GOODS_TYPE = 1;
      sessionStorage.GOODSID = val.id;
      this.$router.options.routes.forEach((item) => {
        if (item.name == "myGoodsz") {
          item.children.forEach((val) => {
            if (val.name == "editGoodsInfo") {
              val.meta.bread[1].name = "全球商品";
              val.meta.bread[1].path = "globalGoods";
            }
          });
        }
      });
      this.$router.push("editGoodsInfo");
    },
    toSend(val) {
      sessionStorage.RELEASE_GOODS = JSON.stringify(val);
  
      this.$router.push("sendGlobalGoods");
    },
  },
};
</script>
<style lang="less">
.globalGoods {
  padding-bottom: 60px;

  .cell {
    background: #fff;
    padding: 30px;
    border-radius: 4px;
    font-size: 14px;
  }

  .cell2 {
    background: #fff;
    padding: 30px;
    margin-top: 30px;
    position: relative;

    .el-tabs__nav {
      height: 50px;
    }

    .el-tabs__active-bar {
      height: 4px;
    }

    .el-tabs__nav-wrap::after {
      height: 1px;
    }

    .list {
      border-radius: 4px;
      border: 1px solid #e9e9e9;
      margin-top: 20px;

      .head {
        display: flex;
        background: #f6f6f6;
        border-bottom: 1px solid #e9e9e9;
        font-size: 14px;
        color: #717373;
        padding: 16px 20px;
      }
    }

    .item {
      padding: 20px;
      border-bottom: 1px solid #e5e5e5;
      font-size: 14px;

      .hang2 {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        /*设置对齐模式*/
        -webkit-line-clamp: 2;
        /*设置多行的行数，示例为2行*/
      }

      .btn {
        color: #5183e1;
        margin-bottom: 6px;
      }

      .btns {
        color: #ccc;
        cursor: no-drop;
        text-decoration: line-through;
      }

      .btn:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .bottom-float-btn {
      position: fixed;
      background: #fff;
      padding: 10px 30px;
      font-size: 16px;
      display: flex;
      bottom: 0;
      left: 245px;
      right: 25px;
      z-index: 1;
      justify-content: space-between;
      align-items: center;
    }

    .line {
      position: absolute;
      bottom: -8px;
      left: 0;
      right: 0;
      margin: auto;
      text-align: center;
      font-size: 12px;
      color: #317bdf;

      span {
        background: #fff;
        padding: 10px 30px;
        cursor: pointer;
      }
    }
  }

  .add-pops {
    width: 100%;
    padding: 30px;
    background: #fafafa;
    margin-top: -20px;

    .el-input__inner {
      border-radius: 30px;
    }

    .el-cascader-menu {
      min-width: 30%;
    }

    .el-cascader-menu__wrap {
      height: 106%;
    }
  }
}
</style>